import {Button, TextField} from "@material-ui/core";
import React, {Component, ReactElement} from "react";
import DataEntry from "../common/forms/dataentry";
import {changeHandler, removeError} from "../common/forms/forms";
import {
  ConfirmEmailValidationRule,
  EmailAddress,
  PhoneNumber,
  Required,
  ValidationContext,
  ValidationSchema,
} from "../common/forms/validation";
import Address, {AddressData} from "./address";
import ErrorSummary from "../common/forms/error-summary";

export interface ParentsInfo {
  parentOneFirstName: string;
  parentOneLastName: string;
  parentOnePhoneNumber: string;
  parentOneEmailAddress: string;
  parentOneEmailAddress2: string; // for confirmation

  parentTwoFirstName: string;
  parentTwoLastName: string;
  parentTwoPhoneNumber: string;
  parentTwoEmailAddress: string;
  parentTwoEmailAddress2: string; // for confirmation

  address: AddressData | null;
}

export interface ParentsInfoPageProps {
  values?: ParentsInfo;
  onGoBack(values: ParentsInfo): void;
  onGoNext(values: ParentsInfo): void;
}

interface ParentsInfoPageState extends ParentsInfo, ValidationContext {}

export default class ParentsInfoPage extends Component<
  ParentsInfoPageProps,
  ParentsInfoPageState
> {
  schema: ValidationSchema<ParentsInfo>;
  private addressControl: React.RefObject<Address>;

  constructor(props: ParentsInfoPageProps) {
    super(props);
    this.addressControl = React.createRef();

    this.state = {
      errors: {},
      parentOneFirstName: "",
      parentOneLastName: "",
      parentOnePhoneNumber: "",
      parentOneEmailAddress: "",
      parentOneEmailAddress2: "",
      parentTwoFirstName: "",
      parentTwoLastName: "",
      parentTwoPhoneNumber: "",
      parentTwoEmailAddress: "",
      parentTwoEmailAddress2: "",
      address: null,
      ...props.values,
    };

    this.schema = new ValidationSchema<ParentsInfo>(
      {
        parentOneFirstName: [new Required()],
        parentOneLastName: [new Required()],
        parentOneEmailAddress: [new Required(), new EmailAddress()],
        parentOneEmailAddress2: [
          new Required(),
          new EmailAddress(),
          new ConfirmEmailValidationRule("parentOneEmailAddress"),
        ],
        parentOnePhoneNumber: [new Required(), new PhoneNumber()],

        parentTwoFirstName: [],
        parentTwoLastName: [],
        parentTwoPhoneNumber: [new PhoneNumber()],
        parentTwoEmailAddress: [new EmailAddress()],
        parentTwoEmailAddress2: [
          new ConfirmEmailValidationRule("parentTwoEmailAddress"),
        ],
        address: [new Required()],
      },
      (result) => {
        this.setState({errors: result.errors});
      }
    );
  }

  prev(): void {
    this.props.onGoBack(this.state);
  }

  onAddresComplete(address: AddressData): void {
    this.setState({
      address,
    });
    removeError(this, "address");
  }

  async validate(): Promise<boolean> {
    const [addressValidation, pageValidation] = await Promise.all([
      this.addressControl.current?.validate(),
      this.schema.validate(this.state),
    ]);

    if (addressValidation?.hasErrors || pageValidation.hasErrors) {
      return false;
    }

    return true;
  }

  getValues(): ParentsInfo {
    return this.state;
  }

  next(): void {
    this.validate().then((success: boolean) => {
      if (success) {
        this.props.onGoNext(this.getValues());
      }
    });
  }

  onUpdate(errors: {[key: string]: string | undefined}): void {
    this.setState({errors});
  }

  render(): ReactElement {
    const {
      address,
      parentOneFirstName,
      parentOneLastName,
      parentTwoFirstName,
      parentTwoLastName,
      parentOneEmailAddress,
      parentOneEmailAddress2,
      parentOnePhoneNumber,
      parentTwoEmailAddress,
      parentTwoEmailAddress2,
      parentTwoPhoneNumber,
      errors,
    } = this.state;
    return (
      <DataEntry errors={errors} onUpdate={this.onUpdate.bind(this)}>
        <div>
          <h2>
            Dane rodzica / opiekuna prawnego zawierającego Umowę o Udział w
            Działaniu
          </h2>
          <dl>
            <dt className="ui-required">
              <label htmlFor="parentOneFirstName">
                Imię rodzica/opiekuna prawnego
              </label>
            </dt>
            <dd>
              <TextField
                id="parentOneFirstName"
                error={!!errors.parentOneFirstName}
                helperText={errors.parentOneFirstName}
                onChange={changeHandler.bind(this)}
                value={parentOneFirstName}
              />
            </dd>
            <dt className="ui-required">
              <label htmlFor="parentOneLastName">
                Nazwisko rodzica/opiekuna prawnego
              </label>
            </dt>
            <dd>
              <TextField
                id="parentOneLastName"
                error={!!errors.parentOneLastName}
                helperText={errors.parentOneLastName}
                onChange={changeHandler.bind(this)}
                value={parentOneLastName}
              />
            </dd>
            <dt className="ui-required">
              <label htmlFor="parentOnePhoneNumber">Telefon</label>
            </dt>
            <dd>
              <TextField
                id="parentOnePhoneNumber"
                error={!!errors.parentOnePhoneNumber}
                helperText={errors.parentOnePhoneNumber}
                onChange={changeHandler.bind(this)}
                value={parentOnePhoneNumber}
              />
            </dd>
            <dt className="ui-required">
              <label htmlFor="parentOneEmailAddress">Adres e-mail</label>
            </dt>
            <dd>
              <TextField
                id="parentOneEmailAddress"
                error={!!errors.parentOneEmailAddress}
                helperText={errors.parentOneEmailAddress}
                onChange={changeHandler.bind(this)}
                value={parentOneEmailAddress}
              />
            </dd>
            <dt className="ui-required">
              <label htmlFor="parentOneEmailAddress2">
                Potwierdź adres e-mail
              </label>
            </dt>
            <dd>
              <TextField
                id="parentOneEmailAddress2"
                error={!!errors.parentOneEmailAddress2}
                helperText={errors.parentOneEmailAddress2}
                onChange={changeHandler.bind(this)}
                value={parentOneEmailAddress2}
              />
            </dd>
          </dl>
          <h2>Dane adresowe do umowy</h2>
          <dl>
            <dd>
              <Address
                value={address || undefined}
                onAddresComplete={this.onAddresComplete.bind(this)}
                ref={this.addressControl}
              />
            </dd>
          </dl>
          <h2>Dane drugiego rodzica / opiekuna prawnego (opcjonalnie)</h2>
          <dl>
            <dt>
              <label htmlFor="parentTwoFirstName">
                Imię drugiego rodzica/opiekuna prawnego
              </label>
            </dt>
            <dd>
              <TextField
                id="parentTwoFirstName"
                onChange={changeHandler.bind(this)}
                value={parentTwoFirstName}
              />
            </dd>
            <dt>
              <label htmlFor="parentTwoLastName">
                Nazwisko drugiego rodzica/opiekuna prawnego
              </label>
            </dt>
            <dd>
              <TextField
                id="parentTwoLastName"
                onChange={changeHandler.bind(this)}
                value={parentTwoLastName}
              />
            </dd>
            <dt>
              <label htmlFor="parentTwoPhoneNumber">Telefon</label>
            </dt>
            <dd>
              <TextField
                id="parentTwoPhoneNumber"
                onChange={changeHandler.bind(this)}
                value={parentTwoPhoneNumber}
              />
            </dd>
            <dt>
              <label htmlFor="parentTwoEmailAddress">
                Adres email drugiego rodzica/opiekuna prawnego
              </label>
            </dt>
            <dd>
              <TextField
                id="parentTwoEmailAddress"
                error={!!errors.parentTwoEmailAddress}
                helperText={errors.parentTwoEmailAddress}
                onChange={changeHandler.bind(this)}
                value={parentTwoEmailAddress}
              />
            </dd>
            <dt>
              <label htmlFor="parentTwoEmailAddress2">
                Potwierdź adres e-mail
              </label>
            </dt>
            <dd>
              <TextField
                id="parentTwoEmailAddress2"
                error={!!errors.parentTwoEmailAddress2}
                helperText={errors.parentTwoEmailAddress2}
                onChange={changeHandler.bind(this)}
                value={parentTwoEmailAddress2}
              />
            </dd>
          </dl>
        </div>
        {Object.keys(errors).length > 0 && <ErrorSummary />}
        <div className="buttons-area space-between">
          <Button onClick={() => this.prev()}>Wstecz</Button>
          <Button onClick={() => this.next()}>Dalej</Button>
        </div>
      </DataEntry>
    );
  }
}
