import {Button, TextField} from "@material-ui/core";
import React, {Component, ReactElement} from "react";
import DataEntry from "../common/forms/dataentry";
import {changeHandler} from "../common/forms/forms";
import Radios from "../common/forms/radios";
import {NamedItem} from "../common/forms/select-named";
import {ValidationContext, ValidationSchema} from "../common/forms/validation";
import ErrorSummary from "../common/forms/error-summary";

export interface OptionalInfo {
  siblings: string;
  friends: string;
  sourceInfo: string;
}

export interface OptionalInfoPageProps {
  values?: OptionalInfo;
  onGoBack(values: OptionalInfo): void;
  onGoNext(values: OptionalInfo): void;
}

const KnownOptions: NamedItem[] = [
  {
    id: "website-bullerbyn",
    name: "ze strony Fundacji Bullerbyn",
  },
  {
    id: "facebook",
    name: "z Facebook'a",
  },
  {
    id: "miastodzieci.pl",
    name: "z portalu miastodzieci.pl",
  },
  {
    id: "internet",
    name: "z internetu",
  },
  {
    id: "acquaintances",
    name: "od znajomych",
  },
  {
    id: "already-before",
    name: "już byliśmy wcześniej",
  },
  {
    id: "in-other-way",
    name: "w inny sposób",
  },
];

interface OptionalInfoPageState extends OptionalInfo, ValidationContext {}

export default class OptionalInfoPage extends Component<
  OptionalInfoPageProps,
  OptionalInfoPageState
> {
  schema: ValidationSchema<OptionalInfo>;

  constructor(props: OptionalInfoPageProps) {
    super(props);
    const {values} = props;

    this.state = {
      errors: {},
      friends: "",
      siblings: "",
      sourceInfo: "",
      ...values,
    };

    this.schema = new ValidationSchema<OptionalInfo>(
      {
        friends: [],
        siblings: [],
        sourceInfo: [],
      },
      (result) => {
        this.setState({errors: result.errors});
      }
    );
  }

  prev(): void {
    this.props.onGoBack(this.state);
  }

  async validate(): Promise<boolean> {
    const pageValidation = await this.schema.validate(this.state);
    return !pageValidation.hasErrors;
  }

  getValues(): OptionalInfo {
    return this.state;
  }

  next(): void {
    this.validate().then((success: boolean) => {
      if (success) {
        this.props.onGoNext(this.getValues());
      }
    });
  }

  onUpdate(errors: {[key: string]: string | undefined}): void {
    this.setState({errors});
  }

  onSourceInfoChange(value: NamedItem) {
    this.setState({
      sourceInfo: value.id,
    });
  }

  render(): ReactElement {
    const {errors, siblings, friends, sourceInfo} = this.state;
    return (
      <DataEntry errors={errors} onUpdate={this.onUpdate.bind(this)}>
        <div>
          <h2>Informacje dodatkowe</h2>
          <dl>
            <dt>
              <label htmlFor="siblings">
                Czy na Wioskę jedzie rodzeństwo i czy ważne jest aby byli w
                jednej grupie (w miarę naszych możliwości organizacyjnych)?
              </label>
            </dt>
            <dd>
              <TextField
                required
                id="siblings"
                error={!!errors.siblings}
                helperText={errors.siblings}
                onChange={changeHandler.bind(this)}
                multiline
                rows={6}
                value={siblings}
              />
            </dd>
            <dt>
              <label htmlFor="friends">
                Czy na Wioskę jadą PRZYJACIELE dziecka (PODAJ KTO) i czy ważne
                jest aby dziecko było z nimi w grupie / pokoju (w miarę naszych
                możliwości organizacyjnych)?
              </label>
            </dt>
            <dd>
              <TextField
                required
                id="friends"
                error={!!errors.friends}
                helperText={errors.friends}
                onChange={changeHandler.bind(this)}
                multiline
                rows={6}
                value={friends}
              />
            </dd>
          </dl>
          <h2>O projekcie dowiedzieliśmy się</h2>
          <Radios
            items={KnownOptions}
            name="sourceInfo"
            onSelect={this.onSourceInfoChange.bind(this)}
            value={sourceInfo}
          />
        </div>
        {Object.keys(errors).length > 0 && <ErrorSummary />}
        <div className="buttons-area space-between">
          <Button onClick={() => this.prev()}>Wstecz</Button>
          <Button onClick={() => this.next()}>Dalej</Button>
        </div>
      </DataEntry>
    );
  }
}
