import {Button, TextField} from "@material-ui/core";
import React, {Component, ReactElement} from "react";
import DataEntry from "../common/forms/dataentry";
import {changeHandler} from "../common/forms/forms";
import {
  Required,
  ValidationContext,
  ValidationSchema,
} from "../common/forms/validation";
import ErrorSummary from "../common/forms/error-summary";

export interface HealthInfo {
  healthIssues?: string;
  diet?: string;
  developmentDisorders?: string;
}

export interface HealthInfoPageProps {
  values?: HealthInfo;
  onGoBack(values: HealthInfo): void;
  onGoNext(values: HealthInfo): void;
}

interface HealthInfoPageState extends HealthInfo, ValidationContext {}

export default class MandatoryInfoPage extends Component<
  HealthInfoPageProps,
  HealthInfoPageState
> {
  schema: ValidationSchema<HealthInfo>;

  constructor(props: HealthInfoPageProps) {
    super(props);

    this.state = {errors: {}, ...props.values};

    this.schema = new ValidationSchema<HealthInfo>(
      {
        healthIssues: [new Required()],
        developmentDisorders: [new Required()],
      },
      (result) => {
        this.setState({errors: result.errors});
      }
    );
  }

  prev(): void {
    this.props.onGoBack(this.state);
  }

  async validate(): Promise<boolean> {
    const pageValidation = await this.schema.validate(this.state);
    return !pageValidation.hasErrors;
  }

  getValues(): HealthInfo {
    return this.state;
  }

  next(): void {
    this.validate().then((success: boolean) => {
      if (success) {
        this.props.onGoNext(this.getValues());
      }
    });
  }

  onUpdate(errors: {[key: string]: string | undefined}): void {
    this.setState({errors});
  }

  render(): ReactElement {
    const {healthIssues, diet, developmentDisorders, errors} = this.state;
    return (
      <DataEntry errors={errors} onUpdate={this.onUpdate.bind(this)}>
        <div>
          <h2>Informacje o dziecku</h2>
          <dl>
            <dt className="ui-required">
              <label htmlFor="healthIssues">
                Czy dziecko choruje przewlekle? Czy potrzebuje specjalnej diety
                z powodów zdrowotnych? Czy wobec dziecka jest wymagane
                specjalne postępowanie pod względem zdrowotnym?
              </label>
            </dt>
            <dd>
              <TextField
                required
                id="healthIssues"
                error={!!errors.healthIssues}
                helperText={errors.healthIssues}
                onChange={changeHandler.bind(this)}
                multiline
                rows={6}
                value={healthIssues}
              />
            </dd>
            <dt className="ui-required">
              <label htmlFor="developmentDisorders">
                Czy dziecko ma podejrzenie lub ma zdiagnozowane trudności
                związane z funkcjonowaniem w grupie (np.: ADHD, Zespół
                Aspergera) lub inne zaburzenia rozwoju?
              </label>
            </dt>
            <dd>
              <TextField
                required
                id="developmentDisorders"
                error={!!errors.developmentDisorders}
                helperText={errors.developmentDisorders}
                onChange={changeHandler.bind(this)}
                multiline
                rows={6}
                value={developmentDisorders}
              />
            </dd>
          </dl>
        </div>
        {Object.keys(errors).length > 0 && <ErrorSummary />}
        <div className="buttons-area space-between">
          <Button onClick={() => this.prev()}>Wstecz</Button>
          <Button onClick={() => this.next()}>Dalej</Button>
        </div>
      </DataEntry>
    );
  }
}
