import React, {Component, ReactElement} from "react";
import {IServices} from "../../service/services";
import OptionalInfoPage, {OptionalInfo} from "./optional-extra-info-page";
import ChildInfoPage, {ChildInfo} from "./child-info-page";
import HealthInfoPage, {HealthInfo} from "./health-info-page";
import ParentsInfoPage, {ParentsInfo} from "./parents-info-page";
import ConsentPage, {ConsentInfo} from "./consent-page";
import AccomodationInfoPage, {AccomodationInfo} from "./accomodation-page";
import Loader from "../common/loader";
import ErrorPanel from "../common/error";
import {ApplicationError} from "../../common/errors";
import {tryTrackEvent} from "../../service/monitoring";

export interface SurveyProps {
  services: IServices;
}

export interface SurveyState {
  page: number;
  childInfo?: ChildInfo;
  parentsInfo?: ParentsInfo;
  healthInfo?: HealthInfo;
  optionalInfo?: OptionalInfo;
  consentInfo?: ConsentInfo;
  accomodationInfo?: AccomodationInfo;
  submitting: boolean;
  error?: ApplicationError;
  confirmationTitle: string;
  confirmationMessage: string;
}

const pages: {[key: number]: string} = {
  1: "Child Info Page",
  2: "Parents Info Page",
  3: "Health Info Page",
  4: "Additional Info Page",
  5: "Consent Page",
  6: "Accomodation Page",
};

export default class Survey extends Component<SurveyProps, SurveyState> {
  constructor(props: SurveyProps) {
    super(props);

    this.state = {
      page: 1,
      submitting: false,
      confirmationTitle: "",
      confirmationMessage: "",
    };
  }

  scrollToTop(): void {
    window.scrollTo({top: 0, behavior: "smooth"});
  }

  goBack(key: keyof SurveyState, values: any) {
    const {page} = this.state;
    this.goTo(page - 1, key, values);
  }

  goNext(key: keyof SurveyState, values: any) {
    const {page} = this.state;
    this.goTo(page + 1, key, values);
  }

  goTo(page: number, key: keyof SurveyState, values: any): void {
    this.setState({
      page,
      [key]: values,
      submitting: false,
      confirmationMessage: "",
      confirmationTitle: "",
    });
    this.scrollToTop();

    tryTrackEvent(`User navigates to ${pages[page]}`);
  }

  goNextEmpty() {
    this.setState({page: this.state.page - 1});
    this.scrollToTop();
  }

  async complete(): Promise<void> {
    const {submitting} = this.state;
    if (submitting) {
      return;
    }

    tryTrackEvent("User submits the application");

    this.setState({
      submitting: true,
      error: undefined,
    });

    const {
      childInfo,
      parentsInfo,
      healthInfo,
      optionalInfo,
      accomodationInfo,
      consentInfo,
    } = this.state;
    const birthDate = childInfo?.childBirthDate;
    const address = parentsInfo?.address;

    if (
      !childInfo ||
      !parentsInfo ||
      !birthDate ||
      !address ||
      !healthInfo ||
      !optionalInfo ||
      !accomodationInfo ||
      !consentInfo
    ) {
      throw new Error("Missing data");
    }

    try {
      const result = await this.props.services.applications.submit({
        child: {
          firstName: childInfo.childFirstName,
          lastName: childInfo.childLastName,
          documentId: childInfo.childPesel,
          class: parseInt(childInfo.childClass),
          birthDate: {
            year: birthDate.getFullYear(),
            month: birthDate.getMonth() + 1,
            day: birthDate.getDate(),
          },
          gender: childInfo.childGender,
        },
        parents: {
          parentOneFirstName: parentsInfo.parentOneFirstName,
          parentOneLastName: parentsInfo.parentOneLastName,
          parentTwoFirstName: parentsInfo.parentTwoFirstName,
          parentTwoLastName: parentsInfo.parentTwoLastName,
          parentOnePhoneNumber: parentsInfo.parentOnePhoneNumber,
          parentOneEmailAddress: parentsInfo.parentOneEmailAddress,
          parentTwoPhoneNumber: parentsInfo.parentTwoPhoneNumber,
          parentTwoEmailAddress: parentsInfo.parentTwoEmailAddress,
          address: {
            city: address.city,
            country: address.country,
            voivodeship: address.voivodeship,
            warsawDistrict: address.community,
            street: address.street,
            zipCode: address.zipCode,
          },
        },
        health: {
          diseases: healthInfo.healthIssues || "",
          dietInfo: healthInfo.diet || "",
          developmentDisorders: healthInfo.developmentDisorders || "",
        },
        additionalInfo: {
          siblings: optionalInfo.siblings,
          friends: optionalInfo.friends,
          alternativeTerms: accomodationInfo.alternativeTerms || "",
          knownThanksTo: optionalInfo.sourceInfo,
        },
        accomodation: {
          termId: accomodationInfo.accomodationTerm?.id || "",
          type: accomodationInfo.accomodationType?.toString() || "",
          recurringParticipant: accomodationInfo.status === "another-time",
          voucher: accomodationInfo.voucher,
        },
        consent: {
          // TODO: this is a quick and dirty solution, assign proper names
          // to consent properties and don't use indexes to get them
          optionalConsent1: consentInfo.checked["5"] || false,
          optionalConsent2: consentInfo.checked["6"] || false,
        },
      });

      if (result.error) {
        this.setState({
          submitting: false,
          error: new ApplicationError(result.error, 400),
        });
      } else {
        this.setState({
          submitting: false,
          confirmationTitle: "Dziękujemy za zgłoszenie!",
          confirmationMessage:
            "Potwierdzenie zgoszenia zostanie przesłane na " +
            "podany adres e-mail.",
        });
      }
    } catch {
      this.setState({
        submitting: false,
        error: new ApplicationError("Błąd techniczny", 400),
      });
    }
  }

  render(): ReactElement {
    const {services} = this.props;
    const {
      page,
      childInfo,
      parentsInfo,
      healthInfo,
      optionalInfo,
      consentInfo,
      accomodationInfo,
      submitting,
      error,
      confirmationTitle,
      confirmationMessage,
    } = this.state;

    if (confirmationTitle && confirmationMessage) {
      return (
        <div id="survey" className="form-container confirmation">
          <h1>{confirmationTitle}</h1>
          <p>{confirmationMessage}</p>
        </div>
      );
    }

    return (
      <div id="survey" className="form-container">
        <h1>Formularz Zgłoszeniowy Wioska Bullerbyn 2025</h1>
        <div>
          {page === 1 && (
            <ChildInfoPage
              onGoNext={(values: ChildInfo) =>
                this.goNext("childInfo", values)
              }
              values={childInfo}
              services={services}
            />
          )}
          {page === 2 && (
            <ParentsInfoPage
              onGoNext={(values: ParentsInfo) =>
                this.goNext("parentsInfo", values)
              }
              onGoBack={(values: ParentsInfo) =>
                this.goBack("parentsInfo", values)
              }
              values={parentsInfo}
            />
          )}
          {page === 3 && (
            <HealthInfoPage
              onGoNext={(values: HealthInfo) =>
                this.goNext("healthInfo", values)
              }
              onGoBack={(values: HealthInfo) =>
                this.goBack("healthInfo", values)
              }
              values={healthInfo}
            />
          )}
          {page === 4 && (
            <OptionalInfoPage
              onGoNext={(values: OptionalInfo) =>
                this.goNext("optionalInfo", values)
              }
              onGoBack={(values: OptionalInfo) =>
                this.goBack("optionalInfo", values)
              }
              values={optionalInfo}
            />
          )}
          {page === 5 && (
            <ConsentPage
              onGoNext={(values: ConsentInfo) =>
                this.goNext("consentInfo", values)
              }
              onGoBack={(values: ConsentInfo) =>
                this.goBack("consentInfo", values)
              }
              values={consentInfo}
            />
          )}
          {page === 6 && (
            <AccomodationInfoPage
              childBirthYear={childInfo?.childBirthDate?.getFullYear() || 2013}
              childGender={childInfo?.childGender || "M"}
              onGoNext={(values: AccomodationInfo) => {
                this.setState({
                  accomodationInfo: values,
                });
                this.complete();
              }}
              onGoBack={(values: AccomodationInfo) =>
                this.goBack("accomodationInfo", values)
              }
              values={accomodationInfo}
              services={services}
            />
          )}
          {submitting && <Loader className="overlay" />}
          {error && <ErrorPanel error={error} />}
        </div>
      </div>
    );
  }
}
