import DataEntry from "../common/forms/dataentry";
import React, {Component, ReactElement} from "react";
import {Conditions} from "../../service/domain/conditions";
import {ValidationContext} from "../common/forms/validation";
import {Button, Checkbox} from "@material-ui/core";
import Field from "../common/forms/field";
import ErrorSummary from "../common/forms/error-summary";

export interface ConsentInfo {
  checked: {[key: string]: boolean};
}

export interface ConsentPageProps {
  values?: ConsentInfo;
  onGoBack(values: ConsentInfo): void;
  onGoNext(values: ConsentInfo): void;
}

interface ConsentPageState extends ConsentInfo, ValidationContext {
  checked: {[key: string]: boolean};
}

export default class ConsentPage extends Component<
  ConsentPageProps,
  ConsentPageState
> {
  constructor(props: ConsentPageProps) {
    super(props);

    const {values} = props;
    this.state = {errors: {}, ...props.values, checked: values?.checked || {}};
  }

  prev(): void {
    this.props.onGoBack(this.state);
  }

  async validate(): Promise<boolean> {
    const {checked} = this.state;
    const errors: {[key: string]: string} = {};

    Conditions.filter((item) => !!item.mandatory).forEach((item, index) => {
      if (!checked[index]) {
        // TODO: localization
        errors[index.toString()] = "Akceptacja jest wymagana";
      }
    });

    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  getValues(): ConsentInfo {
    return this.state;
  }

  next(): void {
    this.validate().then((success: boolean) => {
      if (success) {
        this.props.onGoNext(this.getValues());
      }
    });
  }

  onUpdate(errors: {[key: string]: string | undefined}): void {
    this.setState({errors});
  }

  handleChange(index: number) {
    const {checked} = this.state;
    checked[index] = !checked[index];
    this.setState({
      checked: {...checked},
    });
  }

  render(): ReactElement {
    const {errors, checked} = this.state;
    return (
      <DataEntry errors={errors} onUpdate={this.onUpdate.bind(this)}>
        <div className="conditions">
          <h2>Zgody i oświadczenia</h2>
          <ul>
            {Conditions.map((item, index) => {
              return (
                <li key={index}>
                  <div>
                    <Checkbox
                      id={index.toString()}
                      checked={checked[index] || false}
                      onChange={() => this.handleChange(index)}
                    />
                  </div>
                  <div>
                    <Field error={errors[index.toString()]}>
                      {item.mandatory && (
                        <span className="mandatory-mark">*</span>
                      )}
                      {item.text}
                    </Field>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {Object.keys(errors).length > 0 && <ErrorSummary />}
        <div className="buttons-area space-between">
          <Button onClick={() => this.prev()}>Wstecz</Button>
          <Button onClick={() => this.next()}>Dalej</Button>
        </div>
      </DataEntry>
    );
  }
}
